/** =================== MONTSERRAT =================== **/

/** Montserrat Regular **/
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: normal;
    src: url("Montserrat-Regular.woff2") format("woff2"),
         url("Montserrat-Regular.woff") format("woff");
}

/** Montserrat SemiBold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    font-style: normal;
    src: url("Montserrat-SemiBold.woff2") format("woff2"),
         url("Montserrat-SemiBold.woff") format("woff");
}

/** Montserrat ExtraBold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 800;
    font-style: normal;
    src: url("Montserrat-ExtraBold.woff2") format("woff2"),
         url("Montserrat-ExtraBold.woff") format("woff");
}
